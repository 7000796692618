import React from "react";
import useProjectProgress from "./useProjectProgress";
import useFullpageProgress from "./useFullpageProgress";
import ScrollProgressIndicator from "./ScrollProgressIndicator";

interface CombinedProps {
  trackFullpage?: boolean;
  contentHeight?: number;
}

function FullpageScrollProgressContainer() {
  const { restsOnLanding, translateY } = useFullpageProgress();
  return <ScrollProgressIndicator translateY={translateY} showAnimation={restsOnLanding} />;
}
function ProjectScrollProgressContainer({
  contentHeight,
}: Required<Pick<CombinedProps, "contentHeight">>) {
  const { translateY } = useProjectProgress(contentHeight);
  return <ScrollProgressIndicator translateY={translateY} />;
}

export default ({ trackFullpage, contentHeight }: CombinedProps) => {
  if (!trackFullpage && (contentHeight === undefined || contentHeight === null)) {
    throw new Error(
      `non fullpage progress bars need a valid contentHeight prop, got: ${contentHeight}`,
    );
  }
  return trackFullpage ? (
    <FullpageScrollProgressContainer />
  ) : (
    <ProjectScrollProgressContainer contentHeight={contentHeight!} />
  );
};
