import { useSpring, useTransform, useMotionTemplate, transform } from "framer-motion";
import throttle from "lodash/throttle";
import { useEffect, useState } from "react";
import clamp from "~/utils/clamp";

export default function useProjectProgress(contentHeight: number) {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const percentComplete = (window.scrollY / contentHeight) * 100;

      setProgress(clamp(+percentComplete.toFixed(2), -2, 104));
    }, 20);

    if (contentHeight) {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      };
    }
  }, [contentHeight]);
  const animatedProgress = useSpring(progress, { stiffness: 100, mass: 10, damping: 40 });
  const animatedTranslation = useTransform(animatedProgress, [0, 100], [-100, 0]);
  const translateY = useMotionTemplate`translateY(${animatedTranslation}%)`;

  useEffect(() => {
    animatedProgress.set(progress);
  }, [progress]);

  useEffect(() => {
    function updateTranslate() {
      const translation = transform(animatedProgress.get(), [0, 1], [-100, 0]);
      translateY.set(`translateY(${translation}%)`);
    }
    const unsubscribe = animatedProgress.onChange(updateTranslate);
    return () => {
      unsubscribe();
    };
  }, []);

  return { progress, translateY };
}
