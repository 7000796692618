import { useEffect, useState, useRef } from "react";
import { transform, useMotionTemplate, useSpring, useTransform } from "framer-motion";
import { useScrollContext } from "~/contexts/ScrollContext";
import sectionRoutes from "~/sections/Home/sectionRoutes";

const SCROLL_ANIMATION_DELAY = 3000;
export default function useFullpageProgress() {
  const { scrollIndex } = useScrollContext();
  const [restsOnLanding, setRestsOnLanding] = useState(false);

  const progress = ((scrollIndex || 0) + 1) / sectionRoutes.length;
  const animatedProgress = useSpring(progress, { stiffness: 100, mass: 10, damping: 40 });
  const animatedTranslation = useTransform(animatedProgress, [0, 1], [-100, 0]);
  const translateY = useMotionTemplate`translateY(${animatedTranslation}%)`;
  const animateScrollbarTimer = useRef<number | null>(null);

  useEffect(() => {
    animatedProgress.set(progress);
  }, [progress]);

  useEffect(() => {
    if (scrollIndex === 0 && !animateScrollbarTimer.current) {
      animateScrollbarTimer.current = setTimeout(() => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        clearTimeout(animateScrollbarTimer.current!);
        setRestsOnLanding(true);
      }, SCROLL_ANIMATION_DELAY);
    } else {
      if (animateScrollbarTimer.current) {
        clearTimeout(animateScrollbarTimer.current);
        animateScrollbarTimer.current = null;
      }
      console.log("reset scroll animation");
      setRestsOnLanding(false);
    }
  }, [scrollIndex, setRestsOnLanding]);

  useEffect(() => {
    function updateTranslate() {
      const translation = transform(animatedProgress.get(), [0, 1], [-100, 0]);
      translateY.set(`translateY(${translation}%)`);
    }
    const unsubscribe = animatedProgress.onChange(updateTranslate);
    return () => {
      unsubscribe();
    };
  }, []);

  return { restsOnLanding, translateY };
}
