import styled, { css } from "styled-components";
import { motion } from "framer-motion";

const scrollbarStaticStylesMixin = css`
  position: absolute;
  height: 100%;
  top: 0;
  width: 1px;
  background: #fff;
  left: 0;
`;

export const ScrollIndicator = styled(motion.div).attrs({
  animate: {
    translateY: ["-100%", "100%"],
  },
  transition: {
    repeat: Infinity,
    repeatDelay: 2,
  },
})`
  ${scrollbarStaticStylesMixin}
`;

export const ProgressContainer = styled.div`
  position: relative;
  outline: none;
  user-select: none;
`;

export const Trackline = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(88vh - 40px);
  max-height: 425px;
  width: 1px;
  background: rgba(255, 255, 255, 0.08);
  overflow: hidden;
`;

export const ProgressLine = styled(motion.div)`
  ${scrollbarStaticStylesMixin}
`;
