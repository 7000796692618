import { MotionValue } from "framer-motion";
import React from "react";
import { ProgressContainer, ProgressLine, ScrollIndicator, Trackline } from "./style";

interface ScrollProgressIndicatorProps {
  translateY: MotionValue;
  showAnimation?: boolean;
}

export default function ScrollProgressIndicator({
  translateY,
  showAnimation,
}: ScrollProgressIndicatorProps) {
  return (
    <ProgressContainer tabIndex={-1}>
      <Trackline aria-hidden="true">
        {showAnimation ? <ScrollIndicator /> : <ProgressLine style={{ transform: translateY }} />}
      </Trackline>
    </ProgressContainer>
  );
}
